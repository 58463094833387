import React from "react";

const Button = ({ text }) => {
    return (
        <button className="bg-darkBrown border-transparent rounded-lg text-white px-4 py-2 uppercase">
            {text}
        </button>
    );
};

export default Button;
