import React from "react";
import Badge from "./Badge";

const PostTags = ({ tags }) => {
    return (
        <div>
            {tags !== null &&
                tags.map((tag, index) => <Badge key={index} title={tag} />)}
        </div>
    );
};

export default PostTags;
