import React from "react";

const Badge = ({ title, isActive = true, clickHandler = null }) => {
    if (clickHandler === null) {
        return (
            <span
                className={`font-semibold text-sm mr-2 px-2 py-1 rounded uppercase ${
                    isActive ? "bg-gold" : "bg-grey"
                }`}
            >
                {title}
            </span>
        );
    }

    return (
        <button
            className={`font-semibold text-sm mr-2 px-2 py-1 rounded uppercase ${
                isActive ? "bg-gold" : "bg-grey"
            }`}
            onClick={clickHandler}
        >
            {title}
        </button>
    );
};

export default Badge;
