import React from "react";
import Button from "./Button";

const ItalianCampaignCallToActions = () => {
    return (
        <div className="flex flex-col md:flex-row md:justify-around items-center md:items-start mt-8 mb-4">
            <a href="tel:+39 075.8040410" className="mb-2 md:m-0">
                <Button text="Chiamaci" />
            </a>
            <a
                href="https://booking.slope.it/c8e1dbe3-ddea-4ced-a93e-27bb95d256c0/quote-request?utm_source=blog"
                className="mb-2 md:m-0"
            >
                <Button text="Richiedi preventivo" />
            </a>
            <a href="https://booking.slope.it/c8e1dbe3-ddea-4ced-a93e-27bb95d256c0?utm_source=blog">
                <Button text="Prenota" />
            </a>
        </div>
    );
};

export default ItalianCampaignCallToActions;
