import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Container from "../components/Container";
import ItalianCampaignCallToActions from "../components/ItalianCampaignCallToActions";
import Layout from "../components/Layout";
import PostTags from "../components/PostTags";
import Seo from "../components/Seo";
import { blogContentContainer } from "./blogPostTemplate.module.css";

export const query = graphql`
    query getPost($id: String!, $language: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                author
                description
                date(formatString: "YYYY-MM-DD")
                tags
                image {
                    childImageSharp {
                        gatsbyImageData(
                            aspectRatio: 1.6
                            layout: FULL_WIDTH
                            transformOptions: { fit: COVER }
                        )
                    }
                    publicURL
                }
            }
            html
        }
        locales: allLocale(
            filter: { ns: { in: ["common"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

const BlogPostTemplate = ({ data }) => {
    const {
        frontmatter: { title, date, description, image, author, tags },
        html,
    } = data.markdownRemark;

    return (
        <Layout>
            <Seo
                title={title}
                description={description}
                isArticle={true}
                image={image.publicURL}
            />
            <Container>
                <h1>{title}</h1>
                <small>
                    Pubblicato il <b>{date}</b> da{" "}
                    <b>
                        <i>{author}</i>
                    </b>
                </small>
                <PostTags tags={tags} />
                <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt="cover-articolo"
                    className="rounded-4xl mt-8"
                />
                <div
                    className={`${blogContentContainer} select-none mt-4`}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
                <ItalianCampaignCallToActions />
            </Container>
        </Layout>
    );
};

export default BlogPostTemplate;
